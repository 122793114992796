* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Raleway", sans-serif;
}

#root {
  overflow-x: hidden;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
h3,
h2,
h1,
h4 {
  font-family: "EB Garamond", serif !important;
}
p {
  text-align: justify !important;
  font-family: "EB Garamond", serif !important;
  font-size: 20px !important;
}
:root {
  --hover-color: #bc955c;
  --font-color: #fff;
  --font-color-two: #4d4d4d;
  --background-color: #002856;
  --fera-font: "Playfair Display", serif;
  --para-font: "Raleway", sans-serif;
  --heading-font: "Playfair Display", serif;
  --border-color: #dddddd0f;
}

.heading_comman {
  margin-bottom: 43px;
  font-size: 28px;
  font-family: var(--heading-font);
  font-weight: 600;
  color: var(--background-color);
  text-align: center;
}

.know_more {
  background: var(--hover-color);
  border: 1px solid #fff;
  padding: 5px 20px;
  color: #fff;
  font: 18px "Pigeon";
  transition: all 0.3s ease-in-out;
}
.know_more:hover {
  background: #fff;
  color: var(--hover-color);
}

.img_width {
  width: 100%;
  height: 208px;
  object-fit: cover;
}
.about_heading {
  font: 28px "Pigeon";
  color: #f94f02;
  padding: 10px 0px 25px;
  letter-spacing: 2px;
  margin: 0px;
}
.people_nav_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 3px;
}
.location-icon {
  color: red;
  font-size: 1.5rem;
}
.loading_con {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.loading_con > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_admin {
  width: 100%;
  height: 100vh;
  background-color: var(--background-color);
  position: relative;
}
.login_form_parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 27%;
}
.login_form {
  background-color: #fff;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}
.login_form > h4 {
  text-align: center;
}
.admin_sub_btn {
  width: 100%;
}
.ck-editor__editable_inline {
  min-height: 250px !important;
}
.security-and-privacy {
  min-height: 500px;
  margin-top: 63px;
}
.security-and-privacy p {
  font-size: 15px;
  font-family: var(--para-font);
  text-align: justify;
  color: var(--font-color-two);
  line-height: 1.7;
}
.leaflet-container {
  width: 100%;
  height: 300px;
}

/* reach out and connect */

.reachout {
  background-image: url("./img/banner-contact.jpeg");
  background-size: cover;
  background-position: center;
  background-color: #002856ba;
  position: relative;
  height: 550px;
  width: 100%;
  overflow: hidden; /* Add overflow: hidden to clip the wave animation */
}

.reachout::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00285629;
}

.reachout.home {
  background-image: url("./img/home-banner.jpg");
}

/* Add the wave class for the animation */
.wave {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 80px; /* Adjust the height as needed */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #002856ba
  ); /* Replace with your wave color */
  animation: wave-animation 5s ease-in-out infinite;
}

.auto-container {
  position: absolute;
  bottom: 50%;
  transform: translate(-50%, 100%);
  width: 100%;
  margin: 0 auto;
  left: 50%;
}

.title {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 17px;
  font-family: "Jost", sans-serif;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}

.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 13px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.page-breadcrumb li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.section-parent {
  margin-top: 60px;
}
.section-main {
  margin-top: 60px;
}
.section-left > h2 {
  font-size: 28px;
  font-weight: bold;
  line-height: 60px;
  font-family: "EB Garamond", serif !important;
}
.section-left img {
  height: 400px;
  width: 90%;
}

@keyframes wave-animation {
  0% {
    transform: translateY(10px); /* Start with a slight upward translation */
  }
  50% {
    transform: translateY(-10px); /* Create a dip in the wave */
  }
  100% {
    transform: translateY(10px); /* Return to the starting position */
  }
}

.section-main {
  display: flex;
  justify-content: space-between;
}
.section-left {
  width: 75%;
}
.section-right {
  width: 25%;
}
.section-right ul > li {
  border-bottom: 1px solid #ddd;
  padding: 12px 0;
  width: 74%;
}
.section-right ul > li > a {
  color: #39393b;
  font-size: 16px;
  font-family: "EB Garamond", serif !important;
}

@media only screen and (max-width: 992px) {
  .login_form_parent {
    width: 34%;
  }
  .section-left {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .login_form_parent {
    width: 45%;
  }
  .section-left img {
    height: 250px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .login_form_parent {
    width: 80%;
  }
  .people_nav_container {
    grid-template-columns: 1fr;
  }
  .security-and-privacy {
    padding: 0 5% !important;
  }
}
